@import '../../styles/breakpoints';
@import '../../styles/dimensions';

.backgroundContainer {
    background-color: #111;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 1;

    &.contain {
        background-size: contain;

        @include screen-max-width('xl') {
            background-size: cover;
        }
    }
}

.videoContainer {
    display: flex;
    flex-grow: 1;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 2;

    @include screen-min-width('lg') {
        height: 700px;
    }

    .bgVideo {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 0;

        @include screen-max-width('xl') {
            background-size: cover;
            object-fit: cover;
        }
    }

    .contain {
        object-fit: contain;

        @include screen-max-width('xl') {
            background-size: cover;
            object-fit: cover;
        }
    }

    .cover {
        object-fit: cover;
    }
}

.container {
    left: 0;
    position: relative;
    top: 0;
    z-index: 4;
}

.winnersBadge {
    height: 200px;
    position: absolute;
    right: 10px;
    width: 200px;

    @include screen-max-width('lg') {
        height: 150px;
        right: 0;
        width: 150px;
    }

    @include screen-max-width('sm') {
        display: none;
    }
}

.hasBottomMargin {
    margin-bottom: $gap-xx-large;
}

.contentContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &.isCarousel {
        @include screen-min-width('lg') {
            max-height: 695px;
        }
    }

    @include screen-max-width('lg') {
        flex-direction: column !important;
    }

    .copyContainer {
        display: flex;
        justify-content: center;
        padding: $gap-x-large;
        width: 50%;

        @include screen-max-width('lg') {
            flex-direction: row;
            padding: $gap-small !important;
            width: 100% !important;
        }

        @include screen-max-width('sm') {
            padding: $gap-xx-small !important;
        }

        @include screen-max-width('xs') {
            padding: $gap-xx-small 0 $gap-xx-small 0 !important;
        }

        &.gapSmall {
            padding: $gap-small;
        }

        &.gapMedium {
            padding: $gap-medium;
        }

        &.gapLarge {
            padding: $gap-large;
        }

        &.gapXLarge {
            padding: $gap-x-large;
        }
    }
}

.copyContainer {
    &.isCarousel {
        @include screen-max-width('sm') {
            min-height: 400px !important;
        }
    }
}

.imageContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 50%;

    .column-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @include screen-max-width('lg') {
        width: 100%;

        &.isCarousel {
            display: none !important;
        }
    }
}

.copyContainer {
    @include screen-min-width('lg') {
        min-height: 700px;

        .column-inner {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        h2,
        h1 {
            margin: 0;
        }
    }
}

.column-inner {
    width: 100%;

    @include screen-min-width('md') {
        max-width: 650px;
    }
}

.copy-container {
    @include screen-min-width('md') {
        margin-bottom: $gap-small;
        margin-top: $gap-small;
        width: 100%;
    }
}

.supporting-copy {
    padding-bottom: $gap-small;
    padding-top: $gap-small;
}

.container form {
    align-items: flex-start;
}

.buttons {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $gap-small-medium;
    justify-content: flex-start;
    margin: $gap-large 0 $gap-small 0;
    width: 100%;

    @include screen-min-width('md') {
        flex-direction: row;

        > div,
        a {
            flex: 0 0 50%;
        }
    }
}

.buttonsCentered {
    align-items: center;
    justify-content: center;

    form {
        align-items: center;
    }
}

.mobileBackgroundVideoContainer {
    video {
        object-fit: cover;
        width: 100%;
    }

    @include screen-min-width('md') {
        display: none;
    }
}

.mobileBackgroundImageContainer {
    display: block;

    @include screen-min-width('md') {
        display: none;
    }
}
